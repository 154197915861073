import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle,  IonToolbar, useIonRouter, withIonLifeCycle } from '@ionic/react';
import './Tab1.css';
import { compass, scan, } from 'ionicons/icons';

import Carrusel from "../components/Carrusel2"

import Mapa from '../components/LeafletMap';
import SelectLang from '../components/selectLanguage';
import { useHistory } from 'react-router';
import TouristAssistant from '../components/TouristAssistant';


const Tab1: React.FC = () => {
  const router = useIonRouter();
  const history = useHistory();

  const navigate = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    console.log(event.target);
    router.push('/app/QR');
};

const navigateCompass = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  console.log(event.target);
  router.push('/app/Compass');
};

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar className='custom'>
          {/*<div className="elements-tab1">
            <div className="QR-button">*/}
              <IonButtons slot="start">
              <IonButton onClick={(event) => navigate(event)}>
                <IonIcon size="large" color='light' icon={scan}></IonIcon>
              </IonButton>
              </IonButtons>
            {/*</div>*/}

           {/*<div className="nombre-marca">
              <h2>Mexico Profundo</h2>
            </div>*/}
            <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
            <IonButtons slot="end" className='ion-padding-end'>
            <IonButton onClick={(event) => navigateCompass(event)}>
              <IonIcon size="large" color='light' icon={compass}></IonIcon>
            </IonButton>
            <SelectLang iconColorClass='white-icon'/>
            </IonButtons>
         {/* </div>*/}
          {/* <IonSearchbar placeholder="¿A dónde vas?" className="custom">
            </IonSearchbar> */}
        </IonToolbar>
      </IonHeader>
      <div className="marquee">
        <div className="track">
          {/* <div className="content">&nbsp;Dr Simi: Lo mismo, pero más barato - FONACOT: Siempre a favor de los trabajadores - IMSS: Seguridad y Solidaridad Social - FINABIEN: Servicios Financieros&nbsp;</div> */}
          <div className="content">&nbsp;CONCANACO, Confederación de Cámaras Nacionales de Comercio, Servicios y Turismo. La CONCANACO SERVYTUR es el ÚNICO Organismo del sector COMERCIO, SERVICIOS y TURISMO. La aparición de mensajes se puede programar.&nbsp;</div>
        </div>
      </div>
      <IonContent scrollY={false} scrollX={false} className='hideBgfMap'>
        <Mapa/>
        <Carrusel/>
        <TouristAssistant />
      </IonContent>
      <div className="marquee">
        <div className="track">
          {/* <div className="content">&nbsp;Nacional Monte de Piedad, Lotería Nacional, CO Capital, Échale, Ejido Verde, Extensio, Fondify, Grameen de la Frontera, Handen Consultoría, Hipocampus, Iluméxico, Impacto Transformador, Kubo Financiero, Rayito de Luna, Revitaliza Consultores, Sarape Social, Somos Vía y SVX MX&nbsp;</div> */}
          <div className="content">&nbsp;Avisos Publicitarios de Empresas asociadas a la CONCANACO, o de avisos del Sector Comercial. Pueden aparecer de acuerdo a una calendarización personalizada.&nbsp;</div>
        </div>
      </div>
    </IonPage>
  );
};

export default withIonLifeCycle(Tab1);
