// src/components/CircularMenu.tsx
import React, { useState } from 'react';
import $ from 'jquery';

import './TouristAssistant.css'; // You'll create this CSS file next
import { useIonRouter, withIonLifeCycle } from '@ionic/react';

import { useHistory } from 'react-router';
import NearMuseum from '../pages/NearMuseum';
import NearFood from '../pages/NearFood';
import NearArcheology from '../pages/NearArcheology';
import NearHandCraft from '../pages/NearHandCraft';
import NearCommunity from '../pages/NearCommunity';
import NearCooperative from '../pages/NearCooperative';
import TouristicPlace from '../pages/TouristicPlace';


const TouristAssistant: React.FC = () => {

    const router = useIonRouter();
    const history = useHistory();
  
    const navigateNearMuseum = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        console.log(event.target);
        router.push('/pages/NearMuseum');
    };
    const navigateNearFood = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        console.log(event.target);
        router.push('/pages/NearFood');
    };
    const navigateNearHandCraft = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        console.log(event.target);
        router.push('/pages/NearHandCraft');
    };
    const navigateNearArcheology = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        console.log(event.target);
        router.push('/pages/NearArcheology');
    };
    const navigateNearCommunity = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        console.log(event.target);
        router.push('/pages/NearCommunity');
    };
    const navigateNearCooperative = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        console.log(event.target);
        router.push('/pages/TouristicPlace');
    };

  
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        console.log('toggleMenu');
        $("#mainMenu").toggleClass('open');
      setIsOpen(!isOpen);
    };

    function onClk(){
        console.log('toggleMenu');
        $("#mainMenu").toggleClass('open');
    }

  return (
<div id="mainMenu" className="mainMenuOverlay">
  <div className="itemMenuBox bills"><a onClick={(event) => navigateNearArcheology(event)} className="itemMenu1 "><img className='ajustar-icono-circ' id="arqueologia" src='/assets/IconsCarrusel/Arqueologia.svg'/></a></div>
  <div className="itemMenuBox tarsheed"><a onClick={(event) => navigateNearCommunity(event)} className="itemMenu2 "><img className='ajustar-icono-circ' id="comunidades" src='/assets/IconsCarrusel/Tours.svg'/></a></div>
  <div className="itemMenuBox employees"><a onClick={(event) => navigateNearHandCraft(event)} className="itemMenu3 "><img className='ajustar-icono-circ' id="artesanias" src='/assets/IconsCarrusel/Artesanias.svg'/></a></div>
  <div className="itemMenuBox location"><a onClick={(event) => navigateNearFood(event)} className="itemMenu4 "><img className='ajustar-icono-circ' id="comida" src='/assets/IconsCarrusel/Comida.svg'/></a></div>
  <div className="itemMenuBox eservices"><a onClick={(event) => navigateNearMuseum(event)} className="itemMenu5 "><img className='ajustar-icono-circ' id="museos" src='/assets/IconsCarrusel/Museos.svg'/></a></div>
  <div className="itemMenuBox contact"><a onClick={(event) => navigateNearCooperative(event)} className="itemMenu6 "><img className='ajustar-icono/circ1' id="cooperativa" src='/assets/IconsCarrusel/inpi.png'/></a></div>
  
  <a href="javascript:void(0)" onClick={onClk} className="toggleMenu" ><img className='ajustar-icono-prin' id="mxprof" src='/assets/IconsCarrusel/MXProf.png'/></a>
</div>

  );
};

export default TouristAssistant;
