import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonLoading, useIonModal, useIonRouter } from '@ionic/react';
import React, { useState } from 'react';
import "./Modal.css"
import { RouteComponentProps } from 'react-router';
import { Tipo } from '../interfaces';

import dataArtesanias from '../data/artesanias.json'
import CardArtesnia from "./CardArtesnia";

import dataMuseos from '../data/museos_oaxaca.json'
import CardMuseo from "./CardMuseo";

import dataPueblosMagicos from '../data/pueblosmagicos-fr.json'
import dataCooperativas from '../data/cooperativas.json'

import dataTours from '../data/tours.json'
import CardTour from './CardTour';

import dataHospedaje from '../data/hospedaje.json'
import CardHospedaje from './CardHospedaje';

import RestaurantCard from '../components/Tab2/RestaurantCard';
import dataRestaurante from '../data/restaurantes.json'

import { arrowBack } from 'ionicons/icons';

import dataArq from '../data/zona-arqueologica-fr.json'
import CardArq from './CardArq';
import { useTranslation } from 'react-i18next';
import { MenuSitios } from '../interfaces';

const Modal : React.FC<Tipo> = ({ match }) => {
  const router = useIonRouter();
  const [t,i18n] = useTranslation("global");
    return (
      <React.Suspense>
        <IonPage>
          {(match.params.nom === "arqueologia")?(
            <>
            <IonHeader translucent={true}>
                    <IonToolbar className='Arqueologia header-titles'>
                      <IonButtons slot='start'>
                          <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                              {/* {t("zones-arq.close-btn")} */}
                              <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle>{t("zones-arq.title")}</IonTitle>
                    </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              {dataArq.map(Arq=>
                        <CardArq
                            key={Arq.nombre}
                            nombre={Arq.nombre} 
                            traduccion={Arq.traduccion} 
                            municipio={Arq.municipio} 
                            descripcion={Arq.descripcion} 
                            horario={Arq.horario} 
                            sitio_web={Arq.sitio_web} 
                            precio = {Arq.precio}
                            tipo={Arq.tipo}
                            urlImage={Arq.urlImagen} 
                            promocion={Arq.promocion} 
                        />  
              )}
            </IonContent>
            </>
          ):("")}
        {(match.params.nom === "tours")?(
          <>
          <IonHeader translucent={true}>
                    <IonToolbar className='Tours header-titles'>
                      <IonButtons slot='start'>
                          <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                            {/* {t("tours.close-btn")} */}
                            <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle>{t("tours.title")}</IonTitle>
                    </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              {dataTours.map(tour=>
                        <CardTour
                            key={tour.titulo}
                            estacion={tour.estacion}
                            titulo={tour.titulo}
                            imgPortada={tour.imgPortada}
                            precio={tour.precio}
                            sitioWeb={tour.sitioWeb}
                            actividades={tour.actividades}
                            imgActividades={tour.imgActividades}
                            telefono={tour.telefono}
                            incluye={tour.incluye}
                        />  
              )}
            </IonContent>
          </>
        ):("")}
        {(match.params.nom === "artesanias")?(
          <>
            <IonHeader translucent={true}>
                    <IonToolbar className='Artesanias header-titles'>
                      <IonButtons slot='start'>
                          <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                          {/* {t("artesanias.close-btn")} */}
                            <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle>{t("artesanias.title")}</IonTitle>
                    </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              {dataArtesanias.map(artesania=>
                        <CardArtesnia
                            key={artesania.nombreautor}
                            nombreAutor={artesania.nombreautor}
                            nombrePieza={artesania.nombrepieza}
                            localidad={artesania.localidad}
                            descripcion={artesania.descripcion}
                            historia={artesania.historia}
                            proceso={artesania.procesoproduccion}
                            precio={artesania.precio}
                            urlImg={artesania.urlImagen}
                        />  
              )}
            </IonContent>
          </>
        ):("")}
        {(match.params.nom === "comida")?(
          <>
            <IonHeader translucent={true}>
                      <IonToolbar className='Restaurantes header-titles'>
                        <IonButtons slot='start'>
                            <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                            {/* {t("hostage.close-btn")} */}
                              <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                            </IonButton>
                        </IonButtons>
                        <IonTitle>{t("Tab2.title")}</IonTitle>
                      </IonToolbar>
              </IonHeader>
              <IonContent fullscreen={true}>
                {dataRestaurante.map(restaurante=>
                  <RestaurantCard
                    key = {restaurante.nombre}
                    nombre ={restaurante.nombre}
                    direccion = {restaurante.direccion}
                    imageUrl = {restaurante.urlImagen}
                    calificacion = {restaurante.calificacion}
                    open = {restaurante.horario}
                  />  
                )}
              </IonContent>
          </>
        ):("")}
        {(match.params.nom === "museos")?(
          <>
          <IonHeader translucent={true}>
                    <IonToolbar className='Museos header-titles'>
                      <IonButtons slot='start'>
                          <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                              {/* {t("zones-arq.close-btn")} */}
                              <IonIcon icon={arrowBack} size='large'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle>{t("Museos.title")}</IonTitle>
                    </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              {dataMuseos.map(Mus=>
                        <CardMuseo
                            key={Mus.nombre}
                            nombre={Mus.nombre} 
                            municipio={Mus.municipio} 
                            descripcion={Mus.descripcion} 
                            horario={Mus.horario} 
                            sitio_web={Mus.sitio_web} 
                            precio = {Mus.precio}
                            tipo={Mus.tipo}
                            urlImagen={Mus.urlImagen} 
                            promocion={Mus.promocion} 
                        />  
              )}
            </IonContent>
          </>
        ):("")}
        {(match.params.nom === "transporte")?(
          <>
          <IonHeader translucent={true}>
                    <IonToolbar className='Pueblos Mágicos header-titles'>
                      <IonButtons slot='start'>
                          <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                          {/* {t("hostage.close-btn")} */}
                            <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle>{t("Pueblos Mágicos")}</IonTitle>
                    </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              {dataPueblosMagicos.map(Mus=>
                        <CardMuseo
                        key={Mus.nombre}
                        nombre={Mus.nombre} 
                        municipio={Mus.municipio} 
                        descripcion={Mus.descripcion} 
                        horario={Mus.horario} 
                        sitio_web={Mus.sitio_web} 
                        precio = {Mus.precio}
                        tipo={Mus.tipo}
                        urlImagen={Mus.urlImagen} 
                        promocion={Mus.promocion} 
                    />  
          )}
            </IonContent>

          </>
        ):("")}
        {(match.params.nom === "hospedaje")?(
          <>
          <IonHeader translucent={true}>
                    <IonToolbar className='Hospedajes header-titles'>
                      <IonButtons slot='start'>
                          <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                          {/* {t("hostage.close-btn")} */}
                            <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle>{t("hostage.title")}</IonTitle>
                    </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              {dataHospedaje.map(hospedaje=>
                        <CardHospedaje
                            key={hospedaje.nombre}
                            nombre={hospedaje.nombre}
                            direccion={hospedaje.direccion}
                            telefono={hospedaje.telefono}
                            urlImage={hospedaje.URLimagen}
                            precio={hospedaje.precio_habitacion_sencilla}
                            resena={hospedaje.resena}
                            calificacion={hospedaje.calificacion}
                            incluye={hospedaje.incluye}
                            imgActividades={hospedaje.imgActividades}
                        />  
              )}
            </IonContent>
          </>
        ):("")}
        {(match.params.nom === "cooperativa")?(
          <>
          <IonHeader translucent={true}>
                    <IonToolbar className='Cooperativas header-titles'>
                      <IonButtons slot='start'>
                          <IonButton onClick={(e) => {
                           e.preventDefault();
                           console.log("Ya nos fuimos");
                           router.push('/app/tabs/tab1','back');
                        }} color='light'>
                          {/* {t("hostage.close-btn")} */}
                            <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle>{t("Cooperativas")}</IonTitle>
                    </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
              {dataCooperativas.map(Mus=>
                        <CardMuseo
                        key={Mus.nombre}
                        nombre={Mus.nombre} 
                        municipio={Mus.municipio} 
                        descripcion={Mus.descripcion} 
                        horario={Mus.horario} 
                        sitio_web={Mus.sitio_web} 
                        precio = {Mus.precio}
                        tipo={Mus.tipo}
                        urlImagen={Mus.urlImagen} 
                        promocion={Mus.promocion} 
                    />  
          )}
            </IonContent>

          </>
        ):("")}
        </IonPage>
        </React.Suspense>
    );
};

export default Modal;