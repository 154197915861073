import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import {I18nextProvider} from "react-i18next"

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"
import global_fr from "./translations/fr/global.json"
import global_zh from "./translations/zh/global.json"

i18next.init({
  interpolation:{escapeValue:false},
  resources: {
    es:{
      global: global_es,
    },
    en:{
      global: global_en,
    },
    fr:{
      global: global_fr,
    },
    zh:{
      global: global_zh,
    }
  },
  lng: "en"
})

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  //<React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
  //</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
