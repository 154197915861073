import { IonList, IonItem, IonThumbnail, IonLabel, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert,useIonRouter,useIonViewDidEnter,useIonViewWillEnter,withIonLifeCycle } from '@ionic/react';
import React, { useEffect, useState} from 'react';
import  {
    BarcodeScanner,

  } from '@capacitor-mlkit/barcode-scanning';

import "./NearCommunity.css"
import { RouteComponentProps, useHistory} from 'react-router';
import { arrowBack,alarmSharp,linkSharp, medalSharp } from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import { changeLanguage } from 'i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const NearCommunity: React.FC<RouteComponentProps> = ({match}) => {
    const [t,i18n] = useTranslation("global");
    const [err,setErr] = useState<string>();
    const [present] = useIonAlert();
    const history = useHistory();
    const router = useIonRouter();

    console.log('Language: ',i18n.language);

    const langEnable = i18n.language;
    let mtitle="", mdesc="", mcurrent="", luginteres="", mname10 = "", mhowgochit10 = "", mname11 = "", mhowgochit11 = "", mname12 = "", mhowgochit12 = "", mname13 = "", mhowgochit13 = "", mname14 = "", mhowgochit14 = "", mname15 = "", mhowgochit15 = "";

    switch(langEnable){
        case 'es':
            mtitle = 'Asistente de viaje';
            mdesc = 'En este momento Usted se encuentra en la Riviera Maya. En el Estado de Quintanma Roo, México. Quintana Roo es una región única en el mundo, diversa en cuanto a destinos y atractivos turísticos. Ofrece mar azul, playas de arena blanca, selva tropical, zonas arqueológicas, cenotes, ríos subterráneos, islas, lagunas, cultura Maya viva e historia.';
            mcurrent = 'Como su nombre lo indica, la Riviera Maya fue habitada originalmente por el pueblo maya desde el 200 a. C. Si bien las ciudades que florecieron en la zona no se comparaban a Tikal , Calakmul o Chichen Itzá en cuanto a infraestructura, aún seguían teniendo construcciones en la que se emplearon bases matemáticas y alto nivel de precisión, artes e ingeniería, heredadas de la influencia del Petén y la región Puuc.';
            luginteres = "Comunidades de interés cerca de donde te encuentras...";
            mname10 = "Akatecos";
            mhowgochit10 = "Son un Pueblo Indígena de origen maya, con asentamientos originales en el municipio de San Miguel Acatán, perteneciente al departamento de Huehuetenango, Guatemala. Según una leyenda, de influencia católica, su asentamiento original no estuvo en San Miguel Acatán, sino en Peykonob’. No obstante, la tradición dice que el santo patrono San Miguel Arcángel se sintió mejor en la actual San Miguel Acatán y por eso ordenó que ahí se le construyera una casa, además, les expresó: “Hijos, aquí está bueno, aquí viviremos en nuestra tierra”. De esta manera se fundó el pueblo de San Miguel Acatán. En 1981, algunos akatecos llegaron a México huyendo de la guerra interna que ocurría en ese momento en Guatemala, su país de origen. En marzo de 1982, en Guatemala, Efraín Ríos Montt llegó al poder tras un golpe de estado. Ese mismo año la insurgencia se fortaleció después de unificarse los movimientos guerrilleros entonces existentes. El gobierno de Ríos Montt recrudeció la estrategia de la “tierra arrasada”, implementada desde el gobierno anterior de Lucas García, que consistía en el exterminio de los grupos guerrilleros y de los pueblos indígenas que los pudieran apoyar. A raíz de estos acontecimientos se produjo, en 1981, el éxodo de poblaciones enteras hacia territorio mexicano en donde se formaron campamentos a lo largo de la frontera desde Campeche hasta el Soconusco, Chiapas.";
            mname11 = "Chujes";
            mhowgochit11 = "La tradición oral relata que mucho antes de la Conquista española, los mateanos, los coatanecos y los tojolabales convivían en el mismo territorio. Las pugnas por asentar la autoridad de alguno y el control de las minas de sal fueron las causas por las que finalmente los mateanos se quedaron y los otros migraron. Los coatanecos se asentaron en un espacio aledaño, mientras que los tojolabales, identificados por los chuj como tzapalutas, se distanciaron y modificaron su habla. Desde la firma del Tratado Internacional entre México y Guatemala en 1882, mediante el cual se definieron los límites, algunos chuj se asentaron en territorio mexicano. En algunas décadas, esta población se incrementó en decenas de familias en varias comunidades. Entre 1981 y 1996 ocurrió un éxodo masivo de guatemaltecos, que incluía a miles de chuj, en busca de refugio en tierras mexicanas. Más tarde, a pesar de los Acuerdos de Paz en el vecino país, muchas familias optaron por “integrarse” a México; fue así como se incrementó la población chuj mexicana. Actualmente se localizan en torno a las comunidades chuj más antiguas de la región, que integran dos ejidos fronterizos: Tziscao y Cuauhtémoc. En 1984, el Gobierno Federal “reubicó” otras familias del pueblo chuj, en donde algunas aún permanecen. La concentración de los chuj en la región de Montebello, en Chiapas, se debe lo mismo a factores como el clima y el tipo de cultivos propicios, que a la cercanía con familiares y hablantes de igual idioma. Este hecho se constata por el regreso de algunas personas que habían sido trasladadas a Campeche y por el movimiento hacia esta región de otros grupos de familias que algún tiempo se ubicaron en tierras más cálidas al oriente, en los municipios de Las Margaritas, Maravilla, Tenejapa y Marqués de Comillas.";
            mname12 = "Ixiles";
            mhowgochit12 = "Hacia 1980, en la frontera sur de México con Guatemala se vivió un proceso de migración de población guatemalteca hacia el estado de Chiapas, debido a un contexto de represión militar en aquel país. Algunos integrantes del pueblo ixil se encontraban entre la población migrante. Una vez residiendo en México, los ixiles abandonaron sus rasgos culturales, el vestido y la lengua, como una forma de protección. Entre 1985 y 1986, en Chiapas, se organizó la reubicación de la población refugiada, la cual fue trasladada e instalada en campamentos de los estados de Campeche y Quintana Roo; al correr del tiempo, se conformaron las actuales localidades, integradas con población perteneciente a diferentes pueblos entre ellos ixil, jakalteko, k’iche, kanjobal y q’eqchí’. Desde 1984, a través de la Comisión Mexicana de Ayuda a Refugiados (Comar) y con el soporte financiero del Programa Mundial de Alimentos (PMA) y la Agencia de la ONU para Refugiados (Acnur), se han implementado diversos programas para asistir a esta población migrante en aspectos relacionados a la salud, educación, alimentación y vivienda. En 1989, se planteó la posibilidad de la repatriación voluntaria a la población refugiada. En 1996, se iniciaron diversas acciones institucionales de apoyo, entre éstos el Programa de Regularización Migratoria, el Programa de Naturalización y programas de regularización de la tenencia de la tierra, así como la incorporación a los sistemas nacionales de educación y salud a los ocupantes de los nuevos centros de población.";
            mname13 = "Jakalteco";
            mhowgochit13 = "Los jakaltecos se establecieron en territorio chiapaneco, hace más de 100 años, es decir, poco después de que se firmaran los tratados de límites territoriales entre México y Guatemala, momento a partir del cual la zona aledaña del Soconusco fue transformada en una región muy productiva, a partir de la inversión de recursos extranjeros en el cultivo del café, que había iniciado en años anteriores. Los jakaltecos se encontraban entre la mano de obra que se contrataba en la cosecha de este producto. A finales del siglo XIX, se emitió una ley de colonización para la ocupación de los terrenos nacionales aledaños a la recién creada línea fronteriza con Guatemala. Población hablante de q’anjob’al, chuj, jakalteco y mam, originaria ese país, se asentó en la frontera chiapaneca, naturalizándose posteriormente como ciudadanos mexicanos. Al inicio del mandato presidencial del General Lázaro Cárdenas, los pequeños propietarios que ocupaban terrenos nacionales y los trabajadores de las fincas cafetaleras y ganaderas, hicieron una solicitud para que las fincas se convirtieran en ejidos y pasaran a ser propiedad de los popti’ jakaltecos. Las gestiones fueron arduas, pero casi al final del mandato del General Lázaro Cárdenas, se logró este propósito. En 1982, debido al conflicto armado en Guatemala, llegaron a Chiapas integrantes de diferentes pueblos indígenas, entre ellos población jakalteca. Pero fue hasta 2011 con la promulgación de una nueva Constitución del Estado de Chiapas, que se reconoce a los jakaltecos como un grupo indígena del estado.";
            mname14 = "K'iches";
            mhowgochit14 = "Los jakaltecos se establecieron en territorio chiapaneco, hace más de 100 años, es decir, poco después de que se firmaran los tratados de límites territoriales entre México y Guatemala, momento a partir del cual la zona aledaña del Soconusco fue transformada en una región muy productiva, a partir de la inversión de recursos extranjeros en el cultivo del café, que había iniciado en años anteriores. Los jakaltecos se encontraban entre la mano de obra que se contrataba en la cosecha de este producto. A finales del siglo XIX, se emitió una ley de colonización para la ocupación de los terrenos nacionales aledaños a la recién creada línea fronteriza con Guatemala. Población hablante de q’anjob’al, chuj, jakalteco y mam, originaria ese país, se asentó en la frontera chiapaneca, naturalizándose posteriormente como ciudadanos mexicanos. Al inicio del mandato presidencial del General Lázaro Cárdenas, los pequeños propietarios que ocupaban terrenos nacionales y los trabajadores de las fincas cafetaleras y ganaderas, hicieron una solicitud para que las fincas se convirtieran en ejidos y pasaran a ser propiedad de los popti’ jakaltecos. Las gestiones fueron arduas, pero casi al final del mandato del General Lázaro Cárdenas, se logró este propósito. En 1982, debido al conflicto armado en Guatemala, llegaron a Chiapas integrantes de diferentes pueblos indígenas, entre ellos población jakalteca. Pero fue hasta 2011 con la promulgación de una nueva Constitución del Estado de Chiapas, que se reconoce a los jakaltecos como un grupo indígena del estado.";
            mname15 = "Maya";
            mhowgochit15 = "En la época prehispánica, los mayas construyeron centros cívicos-ceremoniales en torno a los cuales se construían viviendas semidispersas. Pero estos centros fueron desapareciendo debido a conflictos y enfrentamientos bélicos entre linajes. En el siglo XV había un gobierno confederado integrado por los señores sobrevivientes que a su vez se desintegra, conformando un sistema de capitales como Calkiní, Motul, Sotuta, Chetumal y Cozumenl, entre otras, que ejercían el gobierno. Su estructura política, social y económica se dividía en linajes, en primer lugar, estaban los gobernantes, mercaderes y agricultores ricos; luego seguían lo maceguales, que eran los artesanos, agricultores y pescadores y eran los tributarios. Los españoles descubren Yucatán en 1517; logrando la conquista hasta 1546, aunque, debido a las condiciones de abuso, maltrato y opresión, por parte de los españoles, se originan diferentes manifestaciones de rebelión en diferentes momentos por un periodo de más de cien años, a la par de actividades de reordenamiento territorial, congregación y evangelización. Durante este periodo también se vivió la presencia de epidemias de viruela, sarampión y tifus. Pero los problemas no terminaron ahí, durante el resto del periodo colonial, situaciones como el despojo de tierras y en su caso las asignaciones de terrenos insuficientes para la subsistencia de las comunidades mantuvieron condiciones de inestabilidad, que se fueron incrementando, originando la llamada Guerra de Castas, que dio inicio en 1847, movimiento social de gran influencia es aspectos político-territoriales, sociales y económicos, así como culturales, como es en el aspecto de la organización de autoridades tradicionales comunitarias que mantuvieron una estructura de cargos de tipo militar.";
            break;
        case 'en':
            mtitle = 'Travel assistant';
            mdesc = 'Right now you are in the Riviera Maya. In the State of Quintana Roo, Mexico. Quintana Roo is a unique region in the world, diverse in terms of destinations and tourist attractions. It offers blue sea, white sand beaches, rainforest, archaeological sites, cenotes, underground rivers, islands, lagoons, living Mayan culture and history.';
            mcurrent = 'As the name claims, the Riviera Maya was originally inhabited by the Mayan people as early as 200 BC. Although the cities that flourished in the area did not compare to Tikal, Calakmul or Chichen Itzá in terms of infrastructure, they still had constructions in which mathematical bases and a high level of precision, arts and engineering were used, inherited from the influence of the Petén and the Puuc region.';
            luginteres = "Communities of interest near to you...";
            mname10 = "Akatecos";
            mhowgochit10 = "They are an Indigenous People of Mayan origin, with original settlements in the municipality of San Miguel Acatán, belonging to the department of Huehuetenango, Guatemala. According to a Catholic-influenced legend, their original settlement was not in San Miguel Acatán, but in Peykonob'. However, tradition says that the patron saint San Miguel Arcángel felt better in the current San Miguel Acatán and that is why he ordered a house to be built there, and he also told them: “Children, this is good, here we will live in our land”. This is how the town of San Miguel Acatán was founded. In 1981, some Akatecos arrived in Mexico fleeing the internal war that was occurring at that time in Guatemala, their country of origin. In March 1982, in Guatemala, Efraín Ríos Montt came to power after a coup d'état. That same year the insurgency was strengthened after the unification of the then existing guerrilla movements. Ríos Montt's government intensified the “scorched earth” strategy, implemented since the previous government of Lucas García, which consisted in the extermination of the guerrilla groups and the indigenous peoples that could support them. As a result of these events, in 1981, there was an exodus of entire populations to Mexican territory where camps were formed along the border from Campeche to Soconusco, Chiapas.";
            mname11 = "Chujes";
            mhowgochit11 = "Oral tradition tells that long before the Spanish Conquest, the Mateanos, the Coatanecos and the Tojolabales lived together in the same territory. The struggles to establish the authority of one of them and the control of the salt mines were the reasons why the Mateanos finally stayed and the others migrated. The Coatanecos settled in a neighboring area, while the Tojolabales, identified by the Chuj as Tzapalutas, distanced themselves and modified their speech. Since the signing of the International Treaty between Mexico and Guatemala in 1882, which defined the limits, some Chuj settled in Mexican territory. In a few decades, this population increased by dozens of families in several communities. Between 1981 and 1996 there was a massive exodus of Guatemalans, including thousands of Chuj, seeking refuge in Mexican lands. Later, despite the Peace Accords in the neighboring country, many families opted to “integrate” into Mexico; this is how the Mexican Chuj population increased. Today, they are located around the oldest Chuj communities in the region, which make up two border ejidos: Tziscao and Cuauhtémoc. In 1984, the Federal Government “relocated” other Chuj families, where some still remain. The concentration of the Chuj in the Montebello region of Chiapas is due to factors such as the climate and the type of crops that are favorable, as well as the proximity to relatives and speakers of the same language. This fact is confirmed by the return of some people who had been transferred to Campeche and by the movement to this region of other groups of families that for some time were located in warmer lands to the east, in the municipalities of Las Margaritas, Maravilla, Tenejapa and Marqués de Comillas.";
            mname12 = "Ixiles";
            mhowgochit12 = "Around 1980, the southern border of Mexico with Guatemala experienced a process of migration of the Guatemalan population to the state of Chiapas, due to a context of military repression in that country. Some members of the Ixil people were among the migrant population. Once residing in Mexico, the Ixil abandoned their cultural traits, dress and language, as a form of protection. Between 1985 and 1986, in Chiapas, the relocation of the refugee population was organized, which was transferred and installed in camps in the states of Campeche and Quintana Roo; over time, the current localities were formed, made up of people belonging to different peoples, including Ixil, Jakalteko, K'iche, Kanjobal and Q'eqchí'. Since 1984, through the Mexican Commission for Refugee Aid (Comar) and with the financial support of the World Food Program (WFP) and the UN Refugee Agency (UNHCR), various programs have been implemented to assist this migrant population in areas related to health, education, food and housing. In 1989, the possibility of voluntary repatriation of the refugee population was raised. In 1996, various institutional support actions were initiated, including the Migratory Regularization Program, the Naturalization Program and land tenure regularization programs, as well as the incorporation of the occupants of the new population centers into the national education and health systems.";
            mname13 = "Jakalteco";
            mhowgochit13 = "The Jakaltecos settled in Chiapas more than 100 years ago, shortly after the signing of the territorial limits treaties between Mexico and Guatemala, at which time the surrounding area of Soconusco was transformed into a very productive region, due to the investment of foreign resources in coffee cultivation, which had begun in previous years. Jakaltecos were among the labor force hired for the harvest of this product. At the end of the 19th century, a colonization law was issued for the occupation of the national lands bordering the recently created border line with Guatemala. The Q'anjob'al, Chuj, Jakalteco and Mam speaking population, originally from that country, settled in the Chiapas border, later naturalizing as Mexican citizens. At the beginning of the presidential mandate of General Lázaro Cárdenas, the small landowners who occupied national lands and the workers of the coffee and cattle farms, made a request for the farms to be converted into ejidos and become property of the Popti' Jakaltecos. The negotiations were arduous, but almost at the end of General Lázaro Cárdenas' term of office, this goal was achieved. In 1982, due to the armed conflict in Guatemala, members of different indigenous peoples arrived in Chiapas, including the Jakalteca population. But it was not until 2011, with the promulgation of a new Constitution of the State of Chiapas, that the Jakaltecos were recognized as an indigenous group in the state.";
            mname14 = "K'iches";
            mhowgochit14 = "Its origins date back to 600 B.C., but it was not until 1200 A.D., when they arrived in the Guatemalan highlands from Mexican lands. The decline of the K'iche' kingdom coincided with the arrival of the conquistadors, who defeated them in the battle of the valley of Xelajú, Quetzaltenango, where nearly 10,000 members of this people lost their lives, among them their leader Tecun Umán. From then on they were subjected to the new rulers.";
            mname15 = "Maya";
            mhowgochit15 = "In pre-Hispanic times, the Maya built civic-ceremonial centers around which semi-dispersed dwellings were constructed. But these centers gradually disappeared due to conflicts and warlike confrontations between lineages. In the XV century there was a confederate government integrated by the surviving lords that in turn disintegrated, forming a system of capitals such as Calkiní, Motul, Sotuta, Chetumal and Cozumenl, among others, that exercised the government. Its political, social and economic structure was divided into lineages, in the first place, were the rulers, merchants and rich farmers; then followed the maceguals, who were the artisans, farmers and fishermen and were the tributaries. The Spaniards discovered Yucatan in 1517; achieving the conquest until 1546, although, due to the conditions of abuse, mistreatment and oppression by the Spaniards, different manifestations of rebellion originated at different times for a period of more than one hundred years, along with activities of territorial reorganization, congregation and evangelization. During this period there were also epidemics of smallpox, measles and typhus. But the problems did not end there, during the rest of the colonial period, situations such as land dispossession and the allocation of insufficient land for the subsistence of the communities maintained conditions of instability, which increased, originating the so-called Caste War, which began in 1847, a social movement of great influence in political-territorial, social and economic aspects, as well as cultural, as in the aspect of the organization of traditional community authorities that maintained a structure of military type positions.";
            break;
        case 'fr':
            mtitle = 'Assistant de voyage';
            mdesc = 'En ce moment, vous êtes sur la Riviera Maya. Dans l’État de Quintana Roo, au Mexique. Quintana Roo est une région unique au monde, diversifiée en termes de destinations et d’attractions touristiques. Il offre une mer bleue, des plages de sable blanc, une forêt tropicale, des sites archéologiques, des cenotes, des rivières souterraines, des îles, des lagunes, une culture et une histoire mayas vivantes.';
            mcurrent = 'Comme son nom l’indique, la Riviera Maya était à l’origine habitée par le peuple maya depuis 200 av. J.-C. Bien que les villes qui ont prospéré dans la région ne se comparent pas à Tikal, Calakmul ou Chichen Itzá en termes d’infrastructures, elles avaient encore des constructions dans lesquelles des bases mathématiques et un haut niveau de précision, d’art et d’ingénierie étaient utilisés, hérités de l’influence du Petén et de la région de Puuc.';
            luginteres = "Communautés d’intérêt près de chez vous...";
            mname10 = "Akatecos";
            mhowgochit10 = "Il s'agit d'un peuple indigène d'origine maya, dont les premiers établissements se trouvent dans la municipalité de San Miguel Acatán, dans le département de Huehuetenango, au Guatemala. Selon une légende influencée par le catholicisme, leur premier établissement ne se trouvait pas à San Miguel Acatán, mais à Peykonob'. Cependant, la tradition veut que le saint patron San Miguel Arcángel se soit senti mieux dans ce qui est aujourd'hui San Miguel Acatán et qu'il ait ordonné qu'on lui construise une maison à cet endroit, et qu'il leur ait dit : « Les enfants, c'est bien, ici nous vivrons sur notre terre ». C'est ainsi que fut fondé le village de San Miguel Acatán. En 1981, quelques Akatecos sont arrivés au Mexique, fuyant la guerre interne qui se déroulait alors au Guatemala, leur pays d'origine. En mars 1982, au Guatemala, Efraín Ríos Montt prend le pouvoir à la suite d'un coup d'État. Cette même année, l'insurrection s'est renforcée après l'unification des mouvements de guérilla qui existaient à l'époque. Le gouvernement de Ríos Montt a intensifié la stratégie de la « terre brûlée » mise en œuvre depuis le précédent gouvernement de Lucas García, qui consistait à exterminer les groupes de guérilleros et les populations indigènes susceptibles de les soutenir. Ces événements ont conduit à l'exode de populations entières vers le territoire mexicain en 1981, où des camps ont été installés le long de la frontière, de Campeche à Soconusco, au Chiapas.";
            mname11 = "Chujes";
            mhowgochit11 = "La tradition orale raconte que bien avant la conquête espagnole, les Mateanos, les Coatanecos et les Tojolabales vivaient ensemble sur le même territoire. Les luttes pour établir l'autorité de l'un d'entre eux et le contrôle des mines de sel furent à l'origine du maintien des Mateanos et de l'émigration des autres. Les Coatanecos se sont installés dans une zone voisine, tandis que les Tojolabales, identifiés par les Chuj comme des Tzapalutas, ont pris leurs distances et modifié leur discours. Depuis la signature du traité international entre le Mexique et le Guatemala en 1882, qui définissait les frontières, certains Chuj se sont installés en territoire mexicain. En quelques décennies, cette population s'est accrue de plusieurs dizaines de familles dans plusieurs communautés. Entre 1981 et 1996, un exode massif de Guatémaltèques, dont des milliers de Chuj, s'est produit pour trouver refuge au Mexique. Par la suite, malgré les accords de paix dans le pays voisin, de nombreuses familles ont choisi de « s'intégrer » au Mexique, d'où l'augmentation de la population chuj mexicaine. Aujourd'hui, ils se trouvent autour des plus anciennes communautés chuj de la région, qui forment deux ejidos frontaliers : Tziscao et Cuauhtémoc. En 1984, le gouvernement fédéral a « relocalisé » d'autres familles chuj, dont certaines sont toujours présentes. La concentration des Chuj dans la région de Montebello au Chiapas est due à la fois à des facteurs tels que le climat favorable et le type de cultures, et à la proximité de parents et de locuteurs de la même langue. Ce fait est confirmé par le retour de certaines personnes qui avaient été transférées à Campeche et par le déplacement vers cette région d'autres groupes de familles qui, pendant un certain temps, se trouvaient dans des terres plus chaudes à l'est, dans les municipalités de Las Margaritas, Maravilla, Tenejapa et Marqués de Comillas.";
            mname12 = "Ixiles";
            mhowgochit12 = "Vers 1980, la frontière sud du Mexique avec le Guatemala a connu un processus de migration de la population guatémaltèque vers l'État du Chiapas, en raison d'un contexte de répression militaire dans ce pays. Certains membres du peuple Ixil faisaient partie de la population migrante. Une fois installés au Mexique, les Ixil ont abandonné leurs traits culturels, leurs vêtements et leur langue, en guise de protection. Entre 1985 et 1986, au Chiapas, on a organisé le déplacement de la population réfugiée, qui a été transférée et installée dans des camps dans les États de Campeche et de Quintana Roo ; au fil du temps, les localités actuelles se sont formées, composées de personnes appartenant à différents peuples, dont les Ixil, les Jakalteko, les K'iche, les Kanjobal et les Q'eqchí'. Depuis 1984, par l'intermédiaire de la Commission mexicaine d'aide aux réfugiés (Comar) et avec le soutien financier du Programme alimentaire mondial (PAM) et de l'Agence des Nations unies pour les réfugiés (HCR), divers programmes ont été mis en œuvre pour aider cette population migrante dans les domaines de la santé, de l'éducation, de l'alimentation et du logement. En 1989, la possibilité d'un rapatriement volontaire de la population réfugiée a été évoquée. En 1996, diverses actions d'appui institutionnel ont été lancées, notamment le programme de régularisation migratoire, le programme de naturalisation et les programmes de régularisation foncière, ainsi que l'intégration des occupants des nouveaux centres de population dans les systèmes nationaux d'éducation et de santé.";
            mname13 = "Jakalteco";
            mhowgochit13 = "Les Jakaltecos se sont installés au Chiapas il y a plus de 100 ans, peu après la signature des traités de délimitation territoriale entre le Mexique et le Guatemala. À cette époque, la zone environnante de Soconusco s'est transformée en une région hautement productive, grâce à l'investissement de ressources étrangères dans la culture du café, qui avait commencé les années précédentes. Les Jakaltecos faisaient partie de la main-d'œuvre embauchée pour la récolte du café. À la fin du XIXe siècle, une loi de colonisation a été promulguée pour l'occupation des terres nationales bordant la frontière nouvellement créée avec le Guatemala. Les populations de langue Q'anjob'al, Chuj, Jakalteco et Mam, originaires du Guatemala, se sont installées à la frontière du Chiapas et ont été naturalisées mexicaines par la suite. Au début du mandat présidentiel du général Lázaro Cárdenas, les petits propriétaires terriens qui occupaient les terres nationales et les travailleurs des exploitations de café et de bétail ont demandé que les exploitations soient transformées en ejidos et deviennent la propriété des Popti' Jakaltecos. Les négociations ont été ardues, mais presque à la fin du mandat du général Lázaro Cárdenas, cet objectif a été atteint. En 1982, en raison du conflit armé au Guatemala, des membres de différents peuples indigènes sont arrivés au Chiapas, dont la population jakalteca. Mais ce n'est qu'en 2011, avec la promulgation d'une nouvelle Constitution de l'État du Chiapas, que les Jakaltecs ont été reconnus comme groupe autochtone dans l'État.";
            mname14 = "K'iches";
            mhowgochit14 = "Leurs origines remontent à 600 avant J.-C., mais ce n'est qu'en 1200 après J.-C. qu'ils sont arrivés sur les hauts plateaux guatémaltèques en provenance des terres mexicaines. Le déclin du royaume des K'iche' coïncide avec l'arrivée des conquistadors, qui les vainquent lors de la bataille de la vallée de Xelajú, à Quetzaltenango, où près de 10 000 membres de ce peuple perdent la vie, parmi lesquels leur chef Tecun Umán. Dès lors, ils ont été soumis aux nouveaux dirigeants.";
            mname15 = "Maya";
            mhowgochit15 = "À l'époque préhispanique, les Mayas construisaient des centres civiques et cérémoniels autour desquels étaient construites des habitations semi-dispersées. Mais ces centres ont progressivement disparu en raison des conflits et des guerres entre les lignages. Au XVe siècle, il existait un gouvernement confédéré composé des seigneurs survivants, qui s'est à son tour désintégré, formant un système de capitales telles que Calkiní, Motul, Sotuta, Chetumal et Cozumenl, entre autres, qui exerçaient le gouvernement. Sa structure politique, sociale et économique était divisée en lignages : tout d'abord, il y avait les dirigeants, les marchands et les riches agriculteurs ; puis venaient les maceguals, qui étaient les artisans, les agriculteurs et les pêcheurs, et qui étaient les tributaires. Les Espagnols ont découvert le Yucatan en 1517 et l'ont conquis jusqu'en 1546, même si, en raison des abus, des mauvais traitements et de l'oppression des Espagnols, différentes manifestations de rébellion ont vu le jour à différents moments sur une période de plus de cent ans, parallèlement à des activités de réorganisation territoriale, de congrégation et d'évangélisation. Cette période a également été marquée par des épidémies de variole, de rougeole et de typhus. Mais les problèmes ne s'arrêtent pas là : pendant le reste de la période coloniale, des situations telles que la dépossession des terres et l'attribution de terres insuffisantes pour la subsistance des communautés ont maintenu des conditions d'instabilité qui se sont accrues, donnant lieu à ce que l'on appelle la guerre des castes, qui a débuté en 1847, un mouvement social d'une grande influence sur les aspects politico-territoriaux, sociaux et économiques, ainsi que sur les aspects culturels, tels que l'organisation des autorités communautaires traditionnelles, qui maintenaient une structure de postes de type militaire.";
            break;
        case 'zh':
            mtitle = '旅助理';
            mdesc = '今在里维埃拉玛雅。 在墨西哥金塔纳罗奥州。 金塔纳罗奥州者,世界之独无二也,在目与旅游景点异。 供蔚海、白沙、热带雨林、考古遗址、天然井、地下河、岛屿、泻湖、生玛雅文史。';
            mcurrent = '顾名思义,里维埃拉玛雅自公元前 200 以来始居玛雅人。 虽蓬勃发展城邑基础设施不得与蒂卡尔、卡拉克穆尔、奇琴伊察比,然犹有数学之基,高精度艺之功,继佩滕普克之化。';
            luginteres = "您附近的興趣社區...";
            mname10 = "Akatecos";
            mhowgochit10 = "他們是源自瑪雅的原住民，最初的聚落在危地馬拉Huehuetenango省的San Miguel Acatán市。根據受天主教影響的傳說，他們最初的定居地並不在 San Miguel Acatán，而是在 Peykonob'。然而，根據傳統，庇護聖人 San Miguel Arcángel 覺得現在的 San Miguel Acatán 比較好，因此下令在那裡為他蓋一棟房子，他也對他們說：「孩子們，這裡很好，在這裡我們將住在我們的土地上」。San Miguel Acatán 鎮就是這樣建立起來的。1981 年，一些 Akatecos 人為了躲避危地馬拉 (他們的原籍國) 當時的內戰而來到墨西哥。1982 年 3 月，危地馬拉發生政變，Efraín Ríos Montt 上台執政。同年，當時存在的游擊隊運動統一後，叛亂活動日益壯大。Ríos Montt 的政府加強了自前 Lucas García 政府以來實施的 「焦土 」策略，包括滅絕游擊隊組織和可能支持他們的原住民。這些事件導致全部人口在1981年湧入墨西哥領土，在從坎佩切到恰帕斯州索科努斯科的邊境沿線設立營地。";
            mname11 = "Chujes";
            mhowgochit11 = "口耳相傳，早在西班牙征服之前，Mateanos 人、Coatanecos 人和 Tojolabales 人就生活在同一片土地上。為了建立其中一方的權威和控制鹽礦，Mateanos 人最終留下，其他人則遷徙。Coatanecos 人在鄰近地區定居，而被 Chuj 人認定為 Tzapalutas 的 Tojolabales 人則與他們保持距離，並改變了他們的語言。自從 1882 年墨西哥和危地馬拉簽訂國際條約界定邊界後，一些 Chuj 人便定居在墨西哥領土上。在幾十年間，這些人口在幾個社區增加了幾十個家庭。1981 年到 1996 年間，有大批危地馬拉人逃到墨西哥尋求庇護，其中包括數以千計的 Chuj 人。後來，儘管鄰國簽訂了和平協定，許多家庭還是選擇「融入」墨西哥，這就是墨西哥 Chuj 人口增加的原因。如今，他們分布在該地區最古老的 Chuj 社區周圍，組成了兩個邊境 ejidos：Tziscao 和 Cuauhtémoc。1984 年，聯邦政府「遷移」了其他 Chuj 家庭，其中一些家庭至今仍留在當地。Chuj 族人集中在恰帕斯州的 Montebello 地區，是由於有利的氣候和作物種類等因素，以及靠近親戚和說相同語言的人。一些被轉移到坎佩切州的人回歸，以及其他一些曾一度居住在東部較溫暖土地上的家庭遷移到這個地區，如拉斯馬加里塔斯市、馬拉維拉市、特內哈帕市和科米拉斯馬奎斯市，都證實了這個事實。";
            mname12 = "Ixiles";
            mhowgochit12 = "1980 年左右，墨西哥南部與危地馬拉接壤的邊境經歷了危地馬拉人口向恰帕斯州移民的過程，原因是該國的軍事鎮壓。移民人口中有一些 Ixil 族人。一旦居住在墨西哥，Ixil 人就拋棄了他們的文化特徵、衣著和語言，作為一種保護。1985 年至 1986 年間，恰帕斯州組織了難民遷移，這些難民被轉移到坎佩切州和金塔納羅奧州的營地安置；隨著時間的推移，目前的地方形成了，由屬於不同民族的人組成，包括 Ixil、Jakalteko、K「iche、Kanjobal 和 Q」eqchí'。自 1984 年起，透過墨西哥難民援助委員會 (Comar)，並在世界糧食計劃署 (WFP) 和聯合國難民事務署 (UNHCR) 的財政支援下，實施了各種方案，在與健康、教育、糧食和住房相關的領域援助這些移民人口。1989 年，提出了難民自願遣返的可能性。1996年，启动了各种机构支持行动，包括移民正规化方案、归化方案和土地保有权正规化方案，以及将新人口中心的居住者纳入国家教育和卫生系统。";
            mname13 = "Jakalteco";
            mhowgochit13 = "Jakaltecos 人在 100 多年前，即墨西哥與危地馬拉簽訂領土邊界條約之後不久，就定居在恰帕斯州，當時，由於前幾年開始投入外國資源進行咖啡種植，Soconusco 周邊地區已成為高產區。Jakaltecos 是受雇於咖啡收成的勞動力之一。19 世紀末，為了佔領與危地馬拉接壤的國土，頒布了殖民法。原本來自瓜地馬拉的 Q'anjob'al、Chuj、Jakalteco 和 Mam 族人在恰帕斯邊境定居，後來歸化為墨西哥公民。在 Lázaro Cárdenas 將軍的總統任期開始時，佔有國家土地的小土地所有者以及咖啡和牛場的工人請願，要求將農場轉換為 ejidos，成為 Popti' Jakaltecos 的財產。談判過程艱辛，但幾乎在 Lázaro Cárdenas 將軍任期結束時，這個目標終於達成。1982 年，由於危地馬拉的武裝衝突，不同原住民的成員抵達恰帕斯州，包括 Jakalteca 族群。但直到2011年，恰帕斯州新憲法頒佈後，Jakaltecs人才被承認為該州的一個土著群體。";
            mname14 = "K'iches";
            mhowgochit14 = "他們的起源可以追溯到公元前 600 年，但直到公元 1200 年，他們才從墨西哥土地抵達危地馬拉高地。K'iche「王國的衰落與征服者的到來不謀而合，征服者在克薩爾特南戈Xelajú山谷的戰役中擊敗了K」iche「王國，近10,000名K」iche'人喪生，其中包括他們的領袖Tecun Umán。從那時起，他們開始臣服於新的統治者。";
            mname15 = "Maya";
            mhowgochit15 = "在前西班牙時期，馬雅人建造了公民禮儀中心，並在中心周圍建造了半分散的住宅。但由於世系間的衝突和戰爭，這些中心逐漸消失。到了十五世紀，出現了一個由倖存的領主所組成的邦聯政府，後來邦聯政府又解體，形成了一個由 Calkiní、Motul、Sotuta、Chetumal 和 Cozumenl 等都城所組成的系統，由這些都城來行使政府的權力。其政治、社會和經濟結構分為幾個世系，首先是統治者、商人和富農；然後是工匠、農民、漁民和貢獻者。西班牙人於 1517 年發現尤卡坦島，直到 1546 年才完成征服，不過，由於西班牙人的凌辱、虐待和壓迫，在一百多年的時間裡，隨著領土重整、聚居和傳教活動的進行，在不同時期出現了不同表現的叛亂。在此期間，還發生了天花、麻疹和斑疹傷寒等流行病。但問題並未就此結束，在殖民地時期的其餘時間，土地被剝奪和分配的土地不足以讓社區生存的情況，使不穩定情況持續增加，並引發了 1847 年開始的所謂種姓戰爭，這場社會運動在政治領土、社會和經濟方面，以及文化方面都有很大的影響力，例如傳統社區當局的組織維持了軍事型職位的結構。";
            break;
        case 'my':
            mtitle = 'Áantaj náachil';
            mdesc = "Ti' le súutuka' tech Kula'an ti' le Riviera Maya. Ti' le noj u Quintanma Roo, México. Quintana Roo jach jump'éel región chen kaabe', ya'abach en cuanto a destinos yéetel atractivos turísticos. K'u'ubul k'áak'náabo' ch'ooj, playas arena sak, u k'áaxil tropical, zonas arqueológicas, ts'ono'oto'ob, buuts' subterráneos, islas, lagunas, miatsil maaya viva yéetel k'ajláayo'.";
            mcurrent = "Bix u k'aaba' le indica, le Riviera Maya bin habitada originalmente tumen le kaajo' maaya tak le 200 u. kuxtal Wa bien le ciudades florecieron ti' le kúuchila' ma' u comparaban u Tikal, Calakmul wa Chichen Itzá en cuanto a meyaje', láayli' seguían teniendo construcciones ti' le ba'ax ku emplearon bases matemáticas yéetel ka'anal nivel u precisión, Arteso' yéetel ingeniería, heredadas u influencia le Petén yéetel le petenil Puuc.";
            mname10 = "Akatecos";
            mhowgochit10 = "";
            mname11 = "Chujes";
            mhowgochit11 = "";
            mname12 = "Ixiles";
            mhowgochit12 = "";
            mname13 = "Jakalteco";
            mhowgochit13 = "";
            mname14 = "K'iches";
            mhowgochit14 = "";
            mname15 = "Maya";
            mhowgochit15 = "";
            break;
    }

      const navigate = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        router.push('/app/tabs');
    };
      
    
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className='custom'>
                        <IonButtons slot='start'>
                          <IonButton onClick={(event) => navigate(event)} color='light'>
                              <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen={true} className="ion-padding">
                    <div className="content-museo">
                        <div className="title-name">
                            <h1>{mtitle}</h1>
                        </div>
                    </div>
                    <p>
                    {mdesc}
                    </p>
                    <p>
                    {mcurrent}
                    </p>
                    <IonAccordionGroup>
                        <h2>{luginteres}</h2>
                        <IonAccordion value="ten">
                        <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="http://atlas.inpi.gob.mx/wp-content/uploads/akateco-685-1024x679.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname10}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit10}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/akateco-695-1024x647.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/akateco-755-1024x686.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/akateco-fronsur019-1024x675.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="eleven">
                        <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="http://atlas.inpi.gob.mx/wp-content/uploads/chuj-299.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname11}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit11}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/chuj-003copia.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/chuj_18212.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/chuj_18213-c.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="twelve">
                        <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="https://prensacomunitaria.org/wp-content/uploads/2022/06/photo_2022-06-23_05-47-15.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname12}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit12}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`https://dca.gob.gt/noticias-guatemala-diario-centro-america/wp-content/uploads/2017/10/DSC0194.gif`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://togetherwomenrise.org/wp-content/uploads/2017/05/LHI-10.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://prensacomunitaria.org/wp-content/uploads/2022/06/photo_2022-06-23_05-47-15.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="thirdteen">
                        <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="http://atlas.inpi.gob.mx/wp-content/uploads/jakalteco-dsc0458-1024x680.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname13}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit13}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/jakalteco-63795-1024x700.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/jakalteco_18242.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/jakalteco-fronsur022-1024x640.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="fourteen">
                        <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="https://laii.unm.edu/assets/img/digital-resources/kiche.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname14}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit14}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`https://www.worldatlas.com/r/w728-h425-c728x425/upload/8c/2e/1f/shutterstock-502372633.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://i.pinimg.com/originals/68/2b/69/682b690655c1f949aad35ce0ceb42084.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://www.thoughtco.com/thmb/8BwVEyUk0-_Jz_JD4XP-7VOIi40=/2060x1455/filters:fill(auto,1)/GettyImages-542028551-2bc3900f6ce3410f9093336f8b3198ac.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="fifteen">
                        <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="http://atlas.inpi.gob.mx/wp-content/uploads/maya-crw-9647.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname15}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit15}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/maya-campeche33.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/maya-campeche38.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`http://atlas.inpi.gob.mx/wp-content/uploads/maya-89024.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                                
                </IonContent>


            </IonPage>
        );
    
};

export default withIonLifeCycle(NearCommunity);