import {useIonRouter } from '@ionic/react';
import React from 'react';

// import required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper';

import 'swiper/css';
import "./Carrusel.css"

const Carrusel: React.FC = () => {
    const router = useIonRouter();
    const navigate = (event: React.MouseEvent<HTMLElement>, parameter: string) => {
        event.preventDefault();
        console.log(event.target);
        router.push('/app/sitios/' + parameter,'forward');
    };

    return (
            <Swiper slidesPerView={3}
            spaceBetween={30}
            //freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]} 
            className="mySwiper">
                <SwiperSlide><button onClick={(event) => navigate(event,"arqueologia")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-arqueologia'>
                        <img className='ajustar-icono' id="arqueologia" src='/assets/IconsCarrusel/Arqueologia.svg'/>
                        <br></br><div className='text-icono'>Arqueología</div>
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide><button onClick={(event) => navigate(event,"tours")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-tours'>
                        <img className='ajustar-icono' id="tours" src='/assets/IconsCarrusel/Tours.svg'/>
                        <br></br><div className='text-icono'>Tours</div>
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide><button onClick={(event) => navigate(event,"artesanias")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-artesanias'>
                        <img className='ajustar-icono' id="artesanias" src='/assets/IconsCarrusel/Artesanias.svg'/>
                        <br></br> <div className='text-icono'>Artesanías</div>
                    </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide><button onClick={(event) => navigate(event,"comida")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-comida'>
                        <img className='ajustar-icono' id="comida" src='/assets/IconsCarrusel/Comida.svg'/>
                        <br></br><div className='text-icono'>Comida Típica</div>
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide><button onClick={(event) => navigate(event,"museos")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-museos'>
                        <img className='ajustar-icono' id="museos" src='/assets/IconsCarrusel/Museos.svg'/>
                        <br></br><div className='text-icono'>Museos</div>
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide><button onClick={(event) => navigate(event,"transporte")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-pueblomagico'>
                        <img className='ajustar-icono' id="pueblosmagicos" src='/assets/IconsCarrusel/PueblosMagicos.svg'/>
                        <br></br><div className='text-icono'>Pueblos Mágicos</div>
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide><button onClick={(event) => navigate(event,"hospedaje")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-hospedaje'>
                         <img className='ajustar-icono' id="hospedaje" src='/assets/IconsCarrusel/Hospedaje.svg'/>
                         <br></br><div className='text-icono'>Hospedaje</div>
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide><button onClick={(event) => navigate(event,"cooperativa")} className="swipe">
                        <div className='icono-swiper-tab1 icono-color-cooperativa'>
                         <img className='ajustar-icono' id="cooperativa" src='/assets/IconsCarrusel/Cooperativa.svg'/>
                         <br></br><div className='text-icono'>Cooperativas</div>
                        </div>
                    </button>
                </SwiperSlide>
            </Swiper>
    );
};

export default Carrusel;