import { IonList, IonItem, IonThumbnail, IonLabel, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert,useIonRouter,useIonViewDidEnter,useIonViewWillEnter,withIonLifeCycle } from '@ionic/react';
import React, { useEffect, useState} from 'react';
import  {
    BarcodeScanner,

  } from '@capacitor-mlkit/barcode-scanning';

import "./NearFood.css"
import { RouteComponentProps, useHistory} from 'react-router';
import { arrowBack,alarmSharp,linkSharp, medalSharp } from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import { changeLanguage } from 'i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const NearFood: React.FC<RouteComponentProps> = ({match}) => {
    const [t,i18n] = useTranslation("global");
    const [err,setErr] = useState<string>();
    const [present] = useIonAlert();
    const history = useHistory();
    const router = useIonRouter();

    console.log('Language: ',i18n.language);

    const langEnable = i18n.language;
    let mtitle="", mdesc="", mcurrent="", luginteres="", mname5 = "", mhowgochit5 = "";

    switch(langEnable){
        case 'es':
            mtitle = 'Asistente de viaje';
            mdesc = 'En este momento Usted se encuentra en la Riviera Maya. En el Estado de Quintanma Roo, México. Quintana Roo es una región única en el mundo, diversa en cuanto a destinos y atractivos turísticos. Ofrece mar azul, playas de arena blanca, selva tropical, zonas arqueológicas, cenotes, ríos subterráneos, islas, lagunas, cultura Maya viva e historia.';
            mcurrent = 'Como su nombre lo indica, la Riviera Maya fue habitada originalmente por el pueblo maya desde el 200 a. C. Si bien las ciudades que florecieron en la zona no se comparaban a Tikal , Calakmul o Chichen Itzá en cuanto a infraestructura, aún seguían teniendo construcciones en la que se emplearon bases matemáticas y alto nivel de precisión, artes e ingeniería, heredadas de la influencia del Petén y la región Puuc.';
            luginteres = "Lugares de interés cerca de donde te encuentras...";
            mname5 ="Platillos regionales";
            mhowgochit5 = "La comida típica de Quintana Roo se caracteriza por su variedad de platos a base de pescados, mariscos, cerdo y maíz.Algunos de los platos más representativos de la gastronomía de Quintana Roo son: El pescado a la tikin-xic, que se prepara con pescado marinado en achiote y asado al carbón. El makum de repollo, que se elabora con lomo de cerdo marinado en escabeche de naranja y envuelto en hojas de repollo. El pipián, que es una salsa de semillas de calabaza que se sirve con carne de cerdo o pollo. El ceviche de caracol, que se hace con caracol picado y cocido en jugo de limón, cebolla, cilantro y chile. Las enchiladas quintanarroenses, que se rellenan de queso y se bañan en salsa de tomate y chile habanero. La mejor opción para comer rico y a precios accesibles todos los platillos típicos es dirigirse al Centro de Playa del Carmen. Ahí encontrará diversos establecimientos para degustar estos platillos.";
            break;
        case 'en':
            mtitle = 'Travel assistant';
            mdesc = 'Right now you are in the Riviera Maya. In the State of Quintana Roo, Mexico. Quintana Roo is a unique region in the world, diverse in terms of destinations and tourist attractions. It offers blue sea, white sand beaches, rainforest, archaeological sites, cenotes, underground rivers, islands, lagoons, living Mayan culture and history.';
            mcurrent = 'As the name claims, the Riviera Maya was originally inhabited by the Mayan people as early as 200 BC. Although the cities that flourished in the area did not compare to Tikal, Calakmul or Chichen Itzá in terms of infrastructure, they still had constructions in which mathematical bases and a high level of precision, arts and engineering were used, inherited from the influence of the Petén and the Puuc region.';
            luginteres = "Places of interest near you...";
            mname5 ="Regional dishes";
            mhowgochit5 = "The typical food of Quintana Roo is characterized by its variety of dishes based on fish, seafood, pork and corn. Some of the most representative dishes of the gastronomy of Quintana Roo are: Pescado a la tikin-xic, which is prepared with fish marinated in achiote and roasted over charcoal. Cabbage makum, which is made with pork loin marinated in orange pickle and wrapped in cabbage leaves. Pipián, which is a pumpkin seed sauce served with pork or chicken. The snail ceviche, which is made with chopped snail and cooked in lime juice, onion, cilantro and chili. Quintana Roo enchiladas, which are stuffed with cheese and bathed in tomato sauce and habanero pepper. The best option to eat delicious and affordable all the typical dishes is to go to the Center of Playa del Carmen. There you will find various establishments to taste these dishes.";
            break;
        case 'fr':
            mtitle = 'Assistant de voyage';
            mdesc = 'En ce moment, vous êtes sur la Riviera Maya. Dans l’État de Quintana Roo, au Mexique. Quintana Roo est une région unique au monde, diversifiée en termes de destinations et d’attractions touristiques. Il offre une mer bleue, des plages de sable blanc, une forêt tropicale, des sites archéologiques, des cenotes, des rivières souterraines, des îles, des lagunes, une culture et une histoire mayas vivantes.';
            mcurrent = 'Comme son nom l’indique, la Riviera Maya était à l’origine habitée par le peuple maya depuis 200 av. J.-C. Bien que les villes qui ont prospéré dans la région ne se comparent pas à Tikal, Calakmul ou Chichen Itzá en termes d’infrastructures, elles avaient encore des constructions dans lesquelles des bases mathématiques et un haut niveau de précision, d’art et d’ingénierie étaient utilisés, hérités de l’influence du Petén et de la région de Puuc.';
            luginteres = "Lieux d’intérêt près de chez vous...";
            mname5 ="Plats régionaux";
            mhowgochit5 = "La nourriture typique de Quintana Roo se caractérise par sa variété de plats à base de poisson, de fruits de mer, de porc et de maïs. Certains des plats les plus représentatifs de la gastronomie de Quintana Roo sont : Pescado a la tikin-xic, qui est préparé avec du poisson mariné au roucou et rôti au charbon de bois. Le chou makum, qui est fait avec de la longe de porc marinée dans un cornichon à l’orange et enveloppée dans des feuilles de chou. Pipián, qui est une sauce aux graines de citrouille servie avec du porc ou du poulet. Le ceviche d’escargot, qui est fait avec de l’escargot haché et cuit dans du jus de citron vert, de l’oignon, de la coriandre et du piment. Les enchiladas Quintana Roo, farcies au fromage et baignées de sauce tomate et de piment habanero. La meilleure option pour manger tous les plats typiques délicieux et abordables est d’aller au centre de Playa del Carmen. Vous y trouverez divers établissements pour déguster ces plats.";
            break;
        case 'zh':
            mtitle = '旅助理';
            mdesc = '今在里维埃拉玛雅。 在墨西哥金塔纳罗奥州。 金塔纳罗奥州者,世界之独无二也,在目与旅游景点异。 供蔚海、白沙、热带雨林、考古遗址、天然井、地下河、岛屿、泻湖、生玛雅文史。';
            mcurrent = '顾名思义,里维埃拉玛雅自公元前 200 以来始居玛雅人。 虽蓬勃发展城邑基础设施不得与蒂卡尔、卡拉克穆尔、奇琴伊察比,然犹有数学之基,高精度艺之功,继佩滕普克之化。';
            luginteres = "近景点...";
            mname5 ="地方肴馔";
            mhowgochit5 = "金塔纳罗奥州之典礼,以鱼、海鲜、豕、玉米为基。 金塔纳罗奥州食中最具代表性肴, 年:Pescado a la tikin-xic,以 achiote 腌制鱼炙炭。 卷心菜makum,以橙泡菜腌制猪里脊肉,以卷心菜叶裹之。 Pipián,南瓜籽酱也,豕肉鸡肉。 蜗牛酸橘汁腌鱼,以蜗牛切碎为之,用酸橙汁、洋葱、香菜、辣椒烹之。 金塔纳罗奥州辣酱玉米饼馅,实酪,浸番茄酱哈瓦那辣椒中。 凡食美价格合理者,普拉亚德尔卡门中心也。 在那里,你会见百场可以品尝这些菜肴。";
            break;
        case 'my':
            mtitle = 'Áantaj náachil';
            mdesc = "Ti' le súutuka' tech Kula'an ti' le Riviera Maya. Ti' le noj u Quintanma Roo, México. Quintana Roo jach jump'éel región chen kaabe', ya'abach en cuanto a destinos yéetel atractivos turísticos. K'u'ubul k'áak'náabo' ch'ooj, playas arena sak, u k'áaxil tropical, zonas arqueológicas, ts'ono'oto'ob, buuts' subterráneos, islas, lagunas, miatsil maaya viva yéetel k'ajláayo'.";
            mcurrent = "Bix u k'aaba' le indica, le Riviera Maya bin habitada originalmente tumen le kaajo' maaya tak le 200 u. kuxtal Wa bien le ciudades florecieron ti' le kúuchila' ma' u comparaban u Tikal, Calakmul wa Chichen Itzá en cuanto a meyaje', láayli' seguían teniendo construcciones ti' le ba'ax ku emplearon bases matemáticas yéetel ka'anal nivel u precisión, Arteso' yéetel ingeniería, heredadas u influencia le Petén yéetel le petenil Puuc.";
            break;
    }

    


      const navigate = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        router.push('/app/tabs');
    };
      
    
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className='custom'>
                        <IonButtons slot='start'>
                          <IonButton onClick={(event) => navigate(event)} color='light'>
                              <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen={true} className="ion-padding">
                    <div className="content-museo">
                        <div className="title-name">
                            <h1>{mtitle}</h1>
                        </div>
                    </div>
                    <p>
                    {mdesc}
                    </p>
                    <p>
                    {mcurrent}
                    </p>
                    <IonAccordionGroup>
                        <h2>{luginteres}</h2>
                        <IonAccordion value="five">
                            <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="https://tipsparatuviaje.com/wp-content/uploads/2020/02/papadzules-comida.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname5}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit5}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`https://tipsparatuviaje.com/wp-content/uploads/2020/02/pescado-a-la-tikin-xic.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://tipsparatuviaje.com/wp-content/uploads/2020/02/pipian-comida.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://tipsparatuviaje.com/wp-content/uploads/2020/02/makum-de-repollo.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://tipsparatuviaje.com/wp-content/uploads/2020/02/ceviche-de-caracol.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://tipsparatuviaje.com/wp-content/uploads/2020/02/enchiladas-quintanorroenses.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://tipsparatuviaje.com/wp-content/uploads/2020/02/panuchos-comida.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://tipsparatuviaje.com/wp-content/uploads/2020/02/papadzules-comida.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                                
                </IonContent>


            </IonPage>
        );
    
};

export default withIonLifeCycle(NearFood);