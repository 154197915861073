import React, { Suspense, useEffect } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login';
import Register from './pages/Register';

import { languages } from './interfaces';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/*  Button background*/
import './theme/tabs.css'
import Tabs from './pages/Tabs';
import ScannerQR from './pages/ScannerQR';
import FirstContact from './pages/FirstContact';
import NearMuseum from './pages/NearMuseum';
import NearHandCraft from './pages/NearHandCraft';
import NearFood from './pages/NearFood';
import NearArcheology from './pages/NearArcheology';
import NearCommunity from './pages/NearCommunity';
import NearCooperative from './pages/NearCooperative';
import TouristicPlace from './pages/TouristicPlace';

import ShowTourAssist from './pages/ShowTourAsist';
import Modal from './components/Modal2';
import { Device } from '@capacitor/device';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';


setupIonicReact();



const App: React.FC= () => {
  const [ t , i18n ] = useTranslation("global");
  const logDeviceInfo = async () => {
    const LngCode = await Device.getLanguageCode();
    if(languages.includes(LngCode.value)){
      console.log("Lenguaje detectado "+LngCode.value);
      changeLanguage(LngCode.value);
      console.log("Lenguaje configurado "+i18n.language);
    }else{
      console.log("Lenguaje detectado "+LngCode.value);
      changeLanguage("en");
      console.log("Lenguaje configurado "+i18n.language);
    }
  };

  useEffect(()=>{
    logDeviceInfo();
  },[])


  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/" component={Login}/>
          <Route exact path="/register" component={Register}/>
          <Route path="/app/QR" component={ScannerQR}/>
          <Route path="/app/tabs" component={Tabs}/>
          <Route path="/app/Compass" component={FirstContact}/>
          <Route path="/pages/NearMuseum" component={NearMuseum}/>
          <Route path="/pages/NearFood" component={NearFood}/>
          <Route path="/pages/NearHandCraft" component={NearHandCraft}/>
          <Route path="/pages/NearArcheology" component={NearArcheology}/>
          <Route path="/pages/NearCommunity" component={NearCommunity}/>
          <Route path="/pages/NearCooperative" component={NearCooperative}/>
          <Route path="/pages/TouristicPlace" component={TouristicPlace}/>
          <Route exact path="/app/sitios/:nom" component={Modal}/>
        
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
