import { IonList, IonItem, IonThumbnail, IonLabel, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert,useIonRouter,useIonViewDidEnter,useIonViewWillEnter,withIonLifeCycle } from '@ionic/react';
import React, { useEffect, useState} from 'react';
import  {
    BarcodeScanner,

  } from '@capacitor-mlkit/barcode-scanning';

import "./NearCooperative.css"
import { RouteComponentProps, useHistory} from 'react-router';
import { arrowBack,alarmSharp,linkSharp, medalSharp } from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import { changeLanguage } from 'i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const NearCooperative: React.FC<RouteComponentProps> = ({match}) => {
    const [t,i18n] = useTranslation("global");
    const [err,setErr] = useState<string>();
    const [present] = useIonAlert();
    const history = useHistory();
    const router = useIonRouter();

    console.log('Language: ',i18n.language);

    const langEnable = i18n.language;
    let mtitle="", mdesc="", mcurrent="", luginteres="", mname20 = "", mhowgochit20 = "";

    switch(langEnable){
        case 'es':
            mtitle = 'Asistente de viaje';
            mdesc = 'En este momento Usted se encuentra en la Riviera Maya. En el Estado de Quintanma Roo, México. Quintana Roo es una región única en el mundo, diversa en cuanto a destinos y atractivos turísticos. Ofrece mar azul, playas de arena blanca, selva tropical, zonas arqueológicas, cenotes, ríos subterráneos, islas, lagunas, cultura Maya viva e historia.';
            mcurrent = 'Como su nombre lo indica, la Riviera Maya fue habitada originalmente por el pueblo maya desde el 200 a. C. Si bien las ciudades que florecieron en la zona no se comparaban a Tikal , Calakmul o Chichen Itzá en cuanto a infraestructura, aún seguían teniendo construcciones en la que se emplearon bases matemáticas y alto nivel de precisión, artes e ingeniería, heredadas de la influencia del Petén y la región Puuc.';
            luginteres = "Cooperativas de interés cerca de donde te encuentras...";
            mname20 = "Cooperativas de Miel de Abeja";
            mhowgochit20 = "En Quintana Roo, la apicultura representa una fuente de oportunidades y retos para los 3,300 productores que dependen de esta actividad. En los primeros 5 meses del 2021, se comercializaron 2,086 toneladas de miel en el mercado nacional, con un valor de 83.44 millones de pesos. Aunque la meta anual es de 3,946 toneladas, aún faltan por cosechar 1,860 toneladas en los próximos 7 meses1. Además, la Península de Yucatán alberga 17,000 apicultores, quienes producen el 40% de la miel nacional y cosechan una miel de excelente calidad.";
            break;
        case 'en':
            mtitle = 'Travel assistant';
            mdesc = 'Right now you are in the Riviera Maya. In the State of Quintana Roo, Mexico. Quintana Roo is a unique region in the world, diverse in terms of destinations and tourist attractions. It offers blue sea, white sand beaches, rainforest, archaeological sites, cenotes, underground rivers, islands, lagoons, living Mayan culture and history.';
            mcurrent = 'As the name claims, the Riviera Maya was originally inhabited by the Mayan people as early as 200 BC. Although the cities that flourished in the area did not compare to Tikal, Calakmul or Chichen Itzá in terms of infrastructure, they still had constructions in which mathematical bases and a high level of precision, arts and engineering were used, inherited from the influence of the Petén and the Puuc region.';
            luginteres = "Cooperatives of interest near you...";
            mname20 = "Bee Honey Cooperatives";
            mhowgochit20 = "In Quintana Roo, beekeeping represents a source of opportunities and challenges for the 3,300 producers who depend on this activity. In the first 5 months of 2021, 2,086 tons of honey were sold in the national market, with a value of 83.44 million pesos. Although the annual goal is 3,946 tons, 1,860 tons are still to be harvested in the next 7 months1. In addition, the Yucatan Peninsula is home to 17,000 beekeepers, who produce 40% of the nation's honey and harvest excellent quality honey.";
            break;
        case 'fr':
            mtitle = 'Assistant de voyage';
            mdesc = 'En ce moment, vous êtes sur la Riviera Maya. Dans l’État de Quintana Roo, au Mexique. Quintana Roo est une région unique au monde, diversifiée en termes de destinations et d’attractions touristiques. Il offre une mer bleue, des plages de sable blanc, une forêt tropicale, des sites archéologiques, des cenotes, des rivières souterraines, des îles, des lagunes, une culture et une histoire mayas vivantes.';
            mcurrent = 'Comme son nom l’indique, la Riviera Maya était à l’origine habitée par le peuple maya depuis 200 av. J.-C. Bien que les villes qui ont prospéré dans la région ne se comparent pas à Tikal, Calakmul ou Chichen Itzá en termes d’infrastructures, elles avaient encore des constructions dans lesquelles des bases mathématiques et un haut niveau de précision, d’art et d’ingénierie étaient utilisés, hérités de l’influence du Petén et de la région de Puuc.';
            luginteres = "Coopératives d'intérêt près de chez vous...";
            mname20 = "Coopératives de miel d'abeilles";
            mhowgochit20 = "Au Quintana Roo, l'apiculture représente une source d'opportunités et de défis pour les 3 300 producteurs qui dépendent de cette activité. Au cours des cinq premiers mois de 2021, 2 086 tonnes de miel ont été vendues sur le marché national, pour une valeur de 83,44 millions de pesos. Bien que l'objectif annuel soit de 3 946 tonnes, il reste encore 1 860 tonnes à récolter dans les 7 prochains mois1. Par ailleurs, la péninsule du Yucatan compte 17 000 apiculteurs qui produisent 40 % du miel national et récoltent un miel d'excellente qualité.";
            break;
        case 'zh':
            mtitle = '旅助理';
            mdesc = '今在里维埃拉玛雅。 在墨西哥金塔纳罗奥州。 金塔纳罗奥州者,世界之独无二也,在目与旅游景点异。 供蔚海、白沙、热带雨林、考古遗址、天然井、地下河、岛屿、泻湖、生玛雅文史。';
            mcurrent = '顾名思义,里维埃拉玛雅自公元前 200 以来始居玛雅人。 虽蓬勃发展城邑基础设施不得与蒂卡尔、卡拉克穆尔、奇琴伊察比,然犹有数学之基,高精度艺之功,继佩滕普克之化。';
            luginteres = "您附近有興趣的合作社...";
            mname20 = "蜜蜂蜂蜜合作社";
            mhowgochit20 = "在金塔纳罗奥州，养蜂业为3,300名依赖这项活动的生产者带来了机遇和挑战。在 2021 年的前五個月，全國市場共售出 2,086 噸蜂蜜，價值 8,344 萬比索。雖然年度目標是 3,946 噸，但未來 7 個月仍有 1,860 噸的收成1 。此外，尤卡坦半島有 17,000 名蜂農，他們生產全國 40% 的蜂蜜，並收穫品質優良的蜂蜜。";
            break;
        case 'my':
            mtitle = 'Áantaj náachil';
            mdesc = "Ti' le súutuka' tech Kula'an ti' le Riviera Maya. Ti' le noj u Quintanma Roo, México. Quintana Roo jach jump'éel región chen kaabe', ya'abach en cuanto a destinos yéetel atractivos turísticos. K'u'ubul k'áak'náabo' ch'ooj, playas arena sak, u k'áaxil tropical, zonas arqueológicas, ts'ono'oto'ob, buuts' subterráneos, islas, lagunas, miatsil maaya viva yéetel k'ajláayo'.";
            mcurrent = "Bix u k'aaba' le indica, le Riviera Maya bin habitada originalmente tumen le kaajo' maaya tak le 200 u. kuxtal Wa bien le ciudades florecieron ti' le kúuchila' ma' u comparaban u Tikal, Calakmul wa Chichen Itzá en cuanto a meyaje', láayli' seguían teniendo construcciones ti' le ba'ax ku emplearon bases matemáticas yéetel ka'anal nivel u precisión, Arteso' yéetel ingeniería, heredadas u influencia le Petén yéetel le petenil Puuc.";
            break;
    }

    


      const navigate = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        router.push('/app/tabs');
    };
      
    
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className='custom'>
                        <IonButtons slot='start'>
                          <IonButton onClick={(event) => navigate(event)} color='light'>
                              <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen={true} className="ion-padding">
                    <div className="content-museo">
                        <div className="title-name">
                            <h1>{mtitle}</h1>
                        </div>
                    </div>
                    <p>
                    {mdesc}
                    </p>
                    <p>
                    {mcurrent}
                    </p>
                    <IonAccordionGroup>
                        <h2>{luginteres}</h2>
                        <IonAccordion value="first">
                            <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="https://th.bing.com/th/id/OIP.NZFSNFgrJdcj6fuRHvBDWAHaE6?w=278&h=184&c=7&r=0&o=5&dpr=1.3&pid=1.7" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname20}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit20}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`https://th.bing.com/th/id/OIP.NZFSNFgrJdcj6fuRHvBDWAHaE6?w=278&h=184&c=7&r=0&o=5&dpr=1.3&pid=1.7`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://th.bing.com/th/id/OIP._P2QlZP2WLUzfzmmoG_IsAHaEK?w=300&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://th.bing.com/th/id/OIP.MBl2t67RZWD_aItr8vZdmQHaE8?w=252&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                                
                </IonContent>


            </IonPage>
        );
    
};

export default withIonLifeCycle(NearCooperative);